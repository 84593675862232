import React from 'react'

const InstaIcon = () => {
    return (
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="-0.00799561" y="0.000213623" width="37.024" height="37.024" rx="10.68" fill="#F6F6F6" />
            <path d="M18.4656 14.4757C16.2545 14.4757 14.4711 16.2592 14.4711 18.4703C14.4711 20.6814 16.2545 22.4649 18.4656 22.4649C20.6767 22.4649 22.4602 20.6814 22.4602 18.4703C22.4602 16.2592 20.6767 14.4757 18.4656 14.4757ZM18.4656 21.0673C17.0368 21.0673 15.8686 19.9026 15.8686 18.4703C15.8686 17.0379 17.0333 15.8733 18.4656 15.8733C19.898 15.8733 21.0626 17.0379 21.0626 18.4703C21.0626 19.9026 19.8945 21.0673 18.4656 21.0673ZM23.5553 14.3123C23.5553 14.8303 23.1381 15.244 22.6236 15.244C22.1056 15.244 21.6919 14.8268 21.6919 14.3123C21.6919 13.7978 22.1091 13.3806 22.6236 13.3806C23.1381 13.3806 23.5553 13.7978 23.5553 14.3123ZM26.201 15.2579C26.1419 14.0099 25.8568 12.9043 24.9425 11.9934C24.0316 11.0826 22.9261 10.7975 21.678 10.7349C20.3917 10.6619 16.5361 10.6619 15.2498 10.7349C14.0052 10.794 12.8997 11.0791 11.9853 11.99C11.071 12.9008 10.7894 14.0064 10.7268 15.2545C10.6538 16.5408 10.6538 20.3963 10.7268 21.6826C10.7859 22.9307 11.071 24.0363 11.9853 24.9471C12.8997 25.858 14.0017 26.1431 15.2498 26.2056C16.5361 26.2786 20.3917 26.2786 21.678 26.2056C22.9261 26.1465 24.0316 25.8615 24.9425 24.9471C25.8533 24.0363 26.1384 22.9307 26.201 21.6826C26.274 20.3963 26.274 16.5443 26.201 15.2579ZM24.5392 23.0628C24.268 23.7442 23.7431 24.2692 23.0582 24.5438C22.0326 24.9506 19.599 24.8567 18.4656 24.8567C17.3323 24.8567 14.8952 24.9471 13.8731 24.5438C13.1917 24.2727 12.6667 23.7477 12.3921 23.0628C11.9853 22.0372 12.0792 19.6036 12.0792 18.4703C12.0792 17.3369 11.9888 14.8999 12.3921 13.8777C12.6633 13.1963 13.1882 12.6714 13.8731 12.3967C14.8987 11.99 17.3323 12.0838 18.4656 12.0838C19.599 12.0838 22.0361 11.9934 23.0582 12.3967C23.7396 12.6679 24.2645 13.1929 24.5392 13.8777C24.946 14.9033 24.8521 17.3369 24.8521 18.4703C24.8521 19.6036 24.946 22.0407 24.5392 23.0628Z" fill="#191919" />
        </svg>

    )
}

export default InstaIcon